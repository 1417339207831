import formatNumber from 'src/utils/formatNumber'
import prettyAmount from 'src/utils/prettyAmount'

export const usd = { symbol: '$', exponent: 2, iso_code: 'USD' }

const interpolate = (message, data) => {
  if (typeof message == 'string' && typeof data == 'object')
    for (const key in data) {
      const keySearch = new RegExp(`%{${key}}`, 'g')
      const safeValue = (data[key] || '').toString().replace(/\$/g, '$$$$')
      message = message.replace(keySearch, safeValue)
    }

  return message
}

const translate = (key, data, messages) => {
  if (!messages) return
  const parts = Array.isArray(key) ? key : key.split('.')
  const message = messages[parts[0]]
  return parts.length == 1
  ? interpolate(message, data)
  : translate(parts.slice(1), data, message)
}

const I18n = {
  t (key, data) { return translate(key, data, this.messages) },

  formatNumber (amount) {
    return formatNumber(amount, this.currency || {})
  },

  formatCurrency (amount, currency = usd, alwaysDisplayFractional) {
    const amountString = alwaysDisplayFractional
    ? amount.toFixed(currency.exponent)
    : prettyAmount(amount, currency.exponent)

    return (this.currency.format || '%u%n')
    .replace('%u', currency.symbol || '')
    .replace('%c', currency.iso_code || '')
    .replace('%n', this.formatNumber(amountString))
    .trim()
  }
}

const createI18n = config =>
  I18n.isPrototypeOf(config) ? config :
  Object.assign(Object.create(I18n), config)

export default createI18n
