export const backgroundColor = '#fffde2'

export const styles = {
  base: {
    donationBlockOuter: {
      maxWidth: '40em',
      margin: '2em auto',
    },
    donationBlock: {
      backgroundColor,
      transition: 'transform 0.5s',
    },
    donationBlockInner: {
      padding: '1em 1.25em',
    },
    mainCopy: {
      marginBottom: '1em',
    },
    orgChoice: {
      marginBottom: '1em',
    },
    link: {
      textDecoration: 'underline',
    },
    buttons: {
      margin: '-0.2em -0.3em',
    },
    btnColumn: {
      display: 'inline-block',
      padding: '0.2em 0.3em',
      whiteSpace: 'nowrap',
    },
    btn: {
      whiteSpace: 'nowrap',
    },
    poweredBy: {
      fontSize: '0.75em',
      marginBottom: '-0.5em',
      opacity: 0.75,
    },
    poweredByLink: {
      color: 'inherit',
      display: 'inline-block',
      verticalAlign: 'middle',
      margin: '0 0 -0.5em 0.5em',
      textDecoration: 'none',
    },
    poweredByLogo: {
      width: 'auto',
      height: '1.5em',
    },
    moreInfo: {
      textAlign: 'left',
      padding: '1em 1.25em',
    },
    moreInfoClose: {
      textAlign: 'right',
      fontSize: '0.75em',
      marginTop: 0,
    },
    org: {
      margin: '1em 0',
    },
    orgImgColumn: {
      display: 'table-cell',
      verticalAlign: 'middle',
      width: '2.5em',
    },
    orgImg: {
      display: 'block',
      width: '2.5em',
      height: '2.5em',
    },
    orgInfo: {
      display: 'table-cell',
      verticalAlign: 'middle',
    },
    orgLocation: {
      margin: 0,
    },
    orgClear: {
      clear: 'both',
    },
    processingInfo: {
      fontSize: '0.75em',
      marginBottom: 0,
    },
  },

  small: {
    donationBlock: {
      textAlign: 'center',
    },
    buttonsColumn: {
      marginBottom: '1em',
    },
    orgInfo: {
      paddingLeft: '0.5em',
    },
  },

  large: {
    donationBlock: {
      textAlign: 'left',
    },
    buttonsColumn: {
      display: 'table-cell',
      whiteSpace: 'nowrap',
    },
    poweredBy: {
      display: 'table-cell',
      verticalAlign: 'bottom',
      width: '100%',
      textAlign: 'right',
    },
    orgInfo: {
      paddingLeft: '0.75em',
    },
  },
}
