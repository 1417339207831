const formatNumber = (value, { separator = '.', delimiter = ',' }={}) => {
  const amount = value.split('.')
  const places = (amount[0].length) > 3 ? amount[0].length % 3 : 0
  const start  = places ? amount[0].substr(0, places) + delimiter : ''
  const middle = amount[0].substr(places).replace(/(\d{3})(?=\d)/g, `$1${delimiter}`)
  amount[0] = start + middle
  return amount.join(separator)
}

export default formatNumber
