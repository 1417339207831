export const hexToObj = hex => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result && {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  }
}

export const rgbToObj = rgb => {
  if (rgb[3] == 'a')
    console.warn('Colors with alpha are automatically lower contrast and may produce an inaccurate reslt')

  const result = /^rgba?\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)/i.exec(rgb)
  return result && {
    r: parseInt(result[1]),
    g: parseInt(result[2]),
    b: parseInt(result[3]),
  }
}

export const colorToObj = color => {
  if (/^#?([a-f\d]{3}){1,2}$/i.test(color))
    return hexToObj(color)
  else if (/^rgba?\(([\s\d]+,){2,3}([\s\d\.]+)?\s*\)$/i.test(color))
    return rgbToObj(color)
}

export const adjustLightness = (color, amount) => {
  const adjust = c => Math.round(Math.min(Math.max(0, c + amount * 255), 255))
  const { r, g, b } = colorToObj(color)
  return `rgb(${adjust(r)}, ${adjust(g)}, ${adjust(b)})`
}

export const brightness = color => {
  const { r, g, b } = colorToObj(color)
  return ((r * .299) + (g * .587) + (b * .114)) / 255 * 100
}

export const contrast = (color1, color2) =>
  Math.abs(brightness(color1) - brightness(color2))

export const colorContrast = (color, pref, alt, threshold = 45) =>
  contrast(color, pref) > threshold ? pref : alt
