import { h } from 'preact'

/** @jsx h */

const Logo = props =>
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 451.75 102.6">
    <g fill="currentColor">
      <path class="st0" d="M203.92,6.43h-12.59c-0.67,0-1.2,0.54-1.2,1.2v71.08c0,0.67,0.54,1.2,1.2,1.2h12.59c0.67,0,1.2-0.54,1.2-1.2
        V7.63C205.13,6.97,204.59,6.43,203.92,6.43z"/>
      <path class="st0" d="M326.99,6.43H314.4c-0.67,0-1.2,0.54-1.2,1.2V25.7c-3.37-2.49-7.8-4.05-13.53-4.05
        c-15.85,0-26.32,12.39-26.32,28.78c0,7.62,1.96,14,5.5,18.94c1.99,2.78,4.65,5.24,7.58,7.08c4.26,2.68,9.39,4.17,15.45,4.32
        c0.89,0.02,1.76-0.34,2.38-0.97l8.93-9.02v7.94c0,0.67,0.54,1.2,1.2,1.2h12.59c0.67,0,1.2-0.54,1.2-1.2V7.63
        C328.2,6.97,327.66,6.43,326.99,6.43z M313.19,64.83c-2.24,1.19-5.33,2.34-8.68,2.34c-9.54,0-16.54-5.73-16.54-16.81
        c0-9.96,6.66-15.97,14.5-15.97c6.03,0,9.28,2.45,10.72,3.99V64.83z"/>
      <path class="st0" d="M389.17,22.58h-12.59c-0.67,0-1.2,0.54-1.2,1.2v7.32c-1.78-1.69-8.22-7.85-8.92-8.48
        c-0.8-0.73-1.49-1-2.38-0.97c-7.14,0.19-11.65,1.87-15.91,4.56c-8.13,5.12-12.62,13.68-12.62,25.78c0,17.6,11.9,28.28,25.34,28.28
        c6.64,0,10.95-2.19,14.49-5.35v1.25c0,9-4.68,13.16-17.03,13.16c-7.1,0-13.06-2.22-15.67-3.37c-0.67-0.29-1.41,0.2-1.41,0.93v11.59
        c0,0.48,0.28,0.91,0.72,1.1c1.98,0.84,8.05,3.02,18.09,3.02c11.93,0,20.13-3.76,25.05-9.81c4.5-5.54,5.25-12.39,5.25-20.25V23.78
        C390.37,23.12,389.83,22.58,389.17,22.58z M364.66,66.7c-7.85,0-14.5-5.5-14.5-15.46c0-11.08,6.94-16.81,15.17-16.81
        c4.95,0,8.68,2.54,10.05,3.63v24.67C373.92,64.26,370.68,66.7,364.66,66.7z"/>
      <path class="st0" d="M240.06,21.65c-17.01,0-27.09,12.39-27.09,28.78c0,17.97,9.81,30.32,29.59,30.32
        c10.59,0,18.34-2.36,20.28-3.27c0.42-0.2,0.69-0.62,0.69-1.09V65.3c0-0.71-0.7-1.2-1.37-0.96c-2.68,0.94-9.05,2.83-16.95,2.83
        c-8.98,0-15.54-3.49-17.35-11.27h36.55c0.57,0,1.06-0.39,1.18-0.95c0.25-1.21,0.62-3.47,0.62-6.28
        C266.23,33.27,257.07,21.65,240.06,21.65z M227.99,45.12c1.54-6.93,5.89-10.68,12.3-10.68c6.83,0,10.42,4.22,10.99,10.68H227.99z"
        />
      <path class="st0" d="M154.35,6.44h-29.74c-0.92,0-1.66,0.74-1.66,1.66v70.36c0,0.92,0.74,1.66,1.66,1.66h12.19
        c0.92,0,1.66-0.74,1.66-1.66V49.39c0,0,8.83,8.99,9.51,9.6c0.68,0.6,1.25,1.02,2.45,1.02c0.97,0,1.62,0.02,2.78,0.02
        c6.62,0,12.68-1.13,17.5-4.33c7.06-4.68,10.89-12.67,10.89-22.71c0-7.88-2.55-14.7-6.88-19.16C169.69,8.65,163.7,6.44,154.35,6.44z
         M161.49,42.39c-2.5,2.12-5.68,3.21-10.95,3.21c-5.11,0-12.07-0.2-12.07-0.2V20.7h13.38c4.21,0,6.94,0.82,9.19,2.5
        c2.78,2.09,4.35,5.79,4.35,9.85C165.37,36.63,164.21,40.08,161.49,42.39z"/>
      <path class="st0" d="M425.59,21.65c-17.01,0-27.09,12.39-27.09,28.78c0,17.97,9.81,30.32,29.59,30.32
        c10.59,0,18.34-2.36,20.28-3.27c0.42-0.2,0.69-0.62,0.69-1.09V65.3c0-0.71-0.7-1.2-1.37-0.96c-2.68,0.94-9.05,2.83-16.95,2.83
        c-8.98,0-15.54-3.49-17.35-11.27h36.55c0.57,0,1.06-0.39,1.18-0.95c0.25-1.21,0.62-3.47,0.62-6.28
        C451.75,33.27,442.6,21.65,425.59,21.65z M413.52,45.12c1.54-6.93,5.89-10.68,12.3-10.68c6.83,0,10.42,4.22,10.99,10.68H413.52z"/>
      <path class="st1" d="M75.24,0c-7.47,0-13.86,2.6-20.1,8.19c-2.37,2.12-3.98,3.82-5.57,5.88c-1.03-1.23-3.42-4-5.52-5.88
        C37.8,2.6,31.41,0,23.94,0C12.17,0,0,8.75,0,23.4c0,9.35,3.39,16.38,8.31,22.64c3.23,4.1,7.36,8.81,11.3,12.8
        c4.31,4.38,26.6,26.5,28.57,28.36c0.35,0.33,0.79,0.49,1.24,0.49s0.9-0.17,1.26-0.5l9.2-8.7c0.36-0.34,0.57-0.83,0.57-1.33
        c0-0.51-0.2-0.98-0.57-1.33c-0.6-0.57-15-14.79-15-14.79C40.64,56.97,33.21,49.5,29.8,45.98c-4.43-4.56-7.51-8.22-9.21-11.07
        c-2.02-3.4-2.93-6.23-2.93-9.19c0-5.33,3.74-9.21,8.9-9.21c3.72,0,8.06,2.19,12.57,6.33c4.22,3.88,9.95,10.21,10,10.27l0.45,0.5
        l0.45-0.5c0.06-0.06,5.78-6.38,10-10.27c4.64-4.27,8.51-6.25,12.18-6.25c5.34,0,9.36,3.72,9.36,8.64c0,2.84-1.03,5.95-3.16,9.52
        c-1.91,3.21-5.17,7.28-8.95,11.17c-3.2,3.3-11.14,11.24-15.53,15.44c-0.36,0.34-0.47,0.86-0.29,1.32s0.63,0.76,1.13,0.76l18.68,0.08
        c0.01,0,0.02,0,0.03,0c1.18,0,2.31-0.44,3.19-1.23c1.22-1.11,1.84-1.78,3.14-3.18c3.05-3.29,6.25-6.82,9.88-11.45
        c4.75-6.06,9.49-13.91,9.49-24.26C99.17,8.75,87,0,75.24,0z"/>
    </g>
  </svg>

export default Logo
