import countries from '../data/countries'

const orgLocation = org => {
  const country = org.country == 'US' ? 'USA' : countries[org.country]
  const region = org.country == 'US' ? org.state : org.state_name
  const location = [ org.city, region, country ]
    .map(x => x && x.trim()).filter(x => x && x != 'N/A').join(', ')
  return location
}

export default orgLocation
