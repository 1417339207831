const formatNumber = (value, { decimal_mark, thousands_separator }) => {
  const amount = value.split('.')
  const places = (amount[0].length) > 3 ? amount[0].length % 3 : 0
  const start  = places ? amount[0].substr(0, places) + thousands_separator : ''
  const middle = amount[0].substr(places)
  .replace(/(\d{3})(?=\d)/g, `$1${thousands_separator}`)
  amount[0] = start + middle
  return amount.join(decimal_mark)
}

const getSeparators = format =>
  format.indexOf('with_apostrophe_separator') > -1
  ? { decimal_mark: '.', thousands_separator: "'" }
  : format.indexOf('with_comma_separator') > -1
  ? { decimal_mark: ',', thousands_separator: '.' }
  : { decimal_mark: '.', thousands_separator: ',' }

const formatAmount = (amount, format) => {
  const amountString  = Math.abs(amount).toFixed(2)
  const separators    = getSeparators(format)
  const fNumber       = formatNumber(amountString, separators)
  const fAmount       = format.replace(/\{\{\s*amount[a-z_\s]*\}\}/, fNumber)
  return amount >= 0 ? fAmount : `-${fAmount}`
}

export default formatAmount
