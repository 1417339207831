let cloudinaryMappingFolder
const getCloudinaryMappingFolder = () => cloudinaryMappingFolder ||=
  document.querySelector('meta[name="cloudinary-mapping-folder"]')
  .getAttribute('content')

let cloudinaryOrigin
const getCloudinaryOrigin = () => cloudinaryOrigin ||=
  document.querySelector('meta[name="cloudinary-origin"]')
  .getAttribute('content')

const smartEscape = t =>
  encodeURIComponent(t).replace(/(%2C|%2F|%3A)/g, s => decodeURIComponent(s))

const mapKey = key => ({ border: 'bo' })[key] || key[0]

const cloudinaryUrl = (source, options={}, {
  origin=getCloudinaryOrigin(),
  mappingFolder=getCloudinaryMappingFolder(),
}={}) => {
  if (
    source.slice(-4).toLowerCase() == '.svg' &&
    (!options.fetchFormat || options.fetchFormat.toLowerCase() == 'svg')
  ) {
    delete options.width
    delete options.height
    delete options.crop
  }

  const params = smartEscape(Object.keys(options).map(key =>
    [ mapKey(key), options[key] ].join('_')
  ).join())

  return [
    origin,
    params,
    mappingFolder,
    smartEscape(source.replace(/^media\//, '')),
  ].filter(Boolean).join('/')
}

export default cloudinaryUrl
